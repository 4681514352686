import React from "react";

const ScreenSvg = ({ screenshot, classes }) => {
  const { SVG, SVG_992, SVG_768, SVG_mob } = screenshot;

  return (
    <picture>
      <source media="(min-width: 1366px)" srcSet={SVG} />
      <source
        media="(min-width: 992px) and (max-width: 1365px)"
        srcSet={SVG_992}
      />
      <source
        media="(min-width: 768px) and (max-width: 991px)"
        srcSet={SVG_768}
      />
      <source media="(max-width: 767px)" srcSet={SVG_mob} />
      <img src={SVG} alt="" className={classes} />
    </picture>
  );
};

export default ScreenSvg;
