import JPG_PRO from "../../../assets/images/raster/pages/stock-stats/ProInstall/pro.jpg";
import JPG_PRO_2x from "../../../assets/images/raster/pages/stock-stats/ProInstall/pro@2x.jpg";
import JPG_PRO_992 from "../../../assets/images/raster/pages/stock-stats/ProInstall/pro@992.jpg";
import JPG_PRO_992_2x from "../../../assets/images/raster/pages/stock-stats/ProInstall/pro@992_2x.jpg";
import JPG_PRO_768 from "../../../assets/images/raster/pages/stock-stats/ProInstall/pro@768.jpg";
import JPG_PRO_768_2x from "../../../assets/images/raster/pages/stock-stats/ProInstall/pro@768_2x.jpg";
import JPG_PRO_mob from "../../../assets/images/raster/pages/stock-stats/ProInstall/pro@mob.jpg";
import JPG_PRO_mob_2x from "../../../assets/images/raster/pages/stock-stats/ProInstall/pro@mob_2x.jpg";

import WEBP_PRO from "../../../assets/images/raster/pages/stock-stats/ProInstall/pro.webp";
import WEBP_PRO_2x from "../../../assets/images/raster/pages/stock-stats/ProInstall/pro@2x.webp";
import WEBP_PRO_992 from "../../../assets/images/raster/pages/stock-stats/ProInstall/pro@992.webp";
import WEBP_PRO_992_2x from "../../../assets/images/raster/pages/stock-stats/ProInstall/pro@992_2x.webp";
import WEBP_PRO_768 from "../../../assets/images/raster/pages/stock-stats/ProInstall/pro@768.webp";
import WEBP_PRO_768_2x from "../../../assets/images/raster/pages/stock-stats/ProInstall/pro@768_2x.webp";
import WEBP_PRO_mob from "../../../assets/images/raster/pages/stock-stats/ProInstall/pro@mob.webp";
import WEBP_PRO_mob_2x from "../../../assets/images/raster/pages/stock-stats/ProInstall/pro@mob_2x.webp";

import SVG_GOTO from "../../../assets/images/vector/pages/stock-stats/ProInstall/go-to.svg";
import SVG_GOTO_992 from "../../../assets/images/vector/pages/stock-stats/ProInstall/go-to@992.svg";
import SVG_GOTO_768 from "../../../assets/images/vector/pages/stock-stats/ProInstall/go-to@768.svg";
import SVG_GOTO_mob from "../../../assets/images/vector/pages/stock-stats/ProInstall/go-to@mob.svg";

import SVG_ADD from "../../../assets/images/vector/pages/stock-stats/ProInstall/add.svg";
import SVG_ADD_992 from "../../../assets/images/vector/pages/stock-stats/ProInstall/add@992.svg";
import SVG_ADD_768 from "../../../assets/images/vector/pages/stock-stats/ProInstall/add@768.svg";
import SVG_ADD_mob from "../../../assets/images/vector/pages/stock-stats/ProInstall/add@mob.svg";

const PROINSTALL_CONTENT = {
  GO_TO: {
    SVG: SVG_GOTO,
    SVG_992: SVG_GOTO_992,
    SVG_768: SVG_GOTO_768,
    SVG_mob: SVG_GOTO_mob
  },
  ADD: {
    SVG: SVG_ADD,
    SVG_992: SVG_ADD_992,
    SVG_768: SVG_ADD_768,
    SVG_mob: SVG_ADD_mob
  },
  PRO: {
    JPG: JPG_PRO,
    JPG_2x: JPG_PRO_2x,
    JPG_992: JPG_PRO_992,
    JPG_992_2x: JPG_PRO_992_2x,
    JPG_768: JPG_PRO_768,
    JPG_768_2x: JPG_PRO_768_2x,
    JPG_mob: JPG_PRO_mob,
    JPG_mob_2x: JPG_PRO_mob_2x,
    WEBP: WEBP_PRO,
    WEBP_2x: WEBP_PRO_2x,
    WEBP_992: WEBP_PRO_992,
    WEBP_992_2x: WEBP_PRO_992_2x,
    WEBP_768: WEBP_PRO_768,
    WEBP_768_2x: WEBP_PRO_768_2x,
    WEBP_mob: WEBP_PRO_mob,
    WEBP_mob_2x: WEBP_PRO_mob_2x
  }
};

export default PROINSTALL_CONTENT;
