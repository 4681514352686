import React from "react";

const Screen = ({ screenshot, classes }) => {
  const {
    JPG,
    JPG_2x,
    JPG_992,
    JPG_992_2x,
    JPG_768,
    JPG_768_2x,
    JPG_mob,
    JPG_mob_2x,
    WEBP,
    WEBP_2x,
    WEBP_992,
    WEBP_992_2x,
    WEBP_768,
    WEBP_768_2x,
    WEBP_mob,
    WEBP_mob_2x,
  } = screenshot;

  return (
    <picture>
      <source
        type="image/webp"
        media="(min-width: 1366px)"
        srcSet={`${WEBP} 1x, ${WEBP_2x} 2x`}
      />
      <source media="(min-width: 1366px)" srcSet={`${JPG} 1x, ${JPG_2x} 2x`} />
      <source
        type="image/webp"
        media="(min-width: 992px) and (max-width: 1365px)"
        srcSet={`${WEBP_992} 1x, ${WEBP_992_2x} 2x`}
      />
      <source
        media="(min-width: 992px) and (max-width: 1365px)"
        srcSet={`${JPG_992} 1x, ${JPG_992_2x} 2x`}
      />
      <source
        type="image/webp"
        media="(min-width: 768px) and (max-width: 991px)"
        srcSet={`${WEBP_768} 1x, ${WEBP_768_2x} 2x`}
      />
      <source
        media="(min-width: 768px) and (max-width: 991px)"
        srcSet={`${JPG_768} 1x, ${JPG_768_2x} 2x`}
      />
      <source
        type="image/webp"
        media="(max-width: 767px)"
        srcSet={`${WEBP_mob} 1x, ${WEBP_mob_2x} 2x`}
      />
      <source
        media="(max-width: 767px)"
        srcSet={`${JPG_mob} 1x, ${JPG_mob_2x} 2x`}
      />
      <img src={JPG} alt="" loading="lazy" className={classes} />
    </picture>
  );
};

export default Screen;
